$mobile: 720px;

.bottomContainer {
    display: flex;
    gap: 24px;

    @media (max-width: $mobile) {
        flex-direction: column;
    }
}

.section70 {
    flex: 0 1 70%;
    min-width: 0;
}

.section30 {
    flex: 0 1 30%;
    min-width: 0;
}

.bottomContainerLoading {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
