.labelActionsContainer {
    font-size: 0.9rem;
    line-height: 1.5;
}

.pending {
    font-weight: bold;
}

.shortcode {
    top: 0;
}

.labelHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.labelHeader span:nth-child(2) {
    font-weight: normal;
}
