/* disabled to allow no-vendor-prefix as it seems to be the best way to truncate the profile data. */
/* stylelint-disable */

.labelProfile p {
    margin: 0;
}

.contact {
    padding: 0;
    margin: 0;
}

.hideContent {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;

    h2 {
        margin-top: 0;
    }
}

.gpsr {
    white-space: pre-line;
}

table {
    border-collapse: collapse;
    line-height: 18px;
    width: 100%;
    table-layout: auto;
}
