$mobile: 600px;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    background: rgba(0, 0, 0, 0.85);
    height: 100vh;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 6em - 150px);
    margin: 0 65px;

    img {
        object-fit: contain;
    }

    .zoomin & {
        height: calc(100% - 5em);
    }

    @media (max-width: $mobile) {
        display: none;
    }
}

.iconbutton {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    opacity: 0.6;
    outline: none;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.arrow {
    composes: iconbutton;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    margin: auto;

    & + & {
        left: auto;
        right: 0;
    }

    svg {
        height: 60px;
        forced-color-adjust: none;
    }

    &:focus-visible {
        outline: Highlight auto 1px;
    }

    @media (max-width: $mobile) {
        display: none;
    }
}

.wrap {
    height: 100%;
    display: flex;
    align-items: center;
}

.large {
    img {
        display: block;
        max-width: calc(100vw - 10em);
        max-height: calc(100vh - 150px - 5em);
        object-fit: contain;
        border: 2px solid transparent;
    }

    .canzoom & {
        cursor: zoom-in;
    }

    .zoomin & {
        cursor: zoom-out;
        img {
            max-height: calc(100vh - 3em);
        }
    }
}

.thumbnails {
    list-style: none;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    white-space: nowrap;
    margin: 0;
    padding: 1em 0.5em;

    height: calc(150px + 2em);

    .zoomin & {
        visibility: none;
        pointer-events: none;
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        position: fixed;
    }

    @media (max-width: $mobile) {
        overflow: auto;
        padding: 1em;
        padding-top: 4em;
        position: absolute;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.thumbnail {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 3px;
    vertical-align: top;
    height: auto !important;
    /* stylelint-disable a11y/content-property-no-static-value */
    &::before {
        /* zero-width space for VoiceOver ADA */
        /* https://gerardkcohen.me/writing/2017/voiceover-list-style-type.html */
        content: '\200B';
    }

    /* stylelint-disable no-descending-specificity */
    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        border: 2px solid transparent;
    }

    &.active {
        outline: 1px solid white;
        outline-offset: 1px;
    }

    &:focus-visible {
        outline: Highlight auto 1px;
    }

    @media (max-width: $mobile) {
        display: block;
        margin: 0;
        margin-bottom: 1rem;

        &.active {
            outline-offset: 2px;
        }

        img {
            width: 100%;
            height: auto;
            max-height: none;
        }
    }
}

.close {
    composes: iconbutton;

    &:focus-visible {
        outline: Highlight auto 1px;
    }

    svg {
        height: 2em;
        forced-color-adjust: none;
    }
}

.nav {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    height: 4em;
    box-sizing: border-box;

    svg {
        forced-color-adjust: none;
    }

    @media (max-width: $mobile) {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;

        /* stylelint-disable-next-line a11y/no-display-none */
        .zoom,
        .permabutton {
            display: none;
            visibility: none;
        }
    }
}

.button {
    margin-right: 0.5em;
    outline: none;
    &:focus-visible {
        outline: Highlight auto 1px;
    }
}

.permabutton,
.zoom,
.edit {
    composes: button;
}

.permalink {
    max-width: 50%;
    height: 2em;
    box-sizing: border-box;
    padding: 0.5em;
    font: inherit;
    font-family: monospace;
    text-align: center;
    margin-right: 0.5em;
    border-radius: 3px;
    outline: none;

    border: 1px solid lightgray;
    box-shadow: rgba(255, 255, 255, 0.05) 0 1px 0 0 inset, rgba(255, 255, 255, 0.024) -1px 0 0 0 inset,
        rgba(255, 255, 255, 0.024) 1px 0 0 0 inset, rgba(0, 0, 0, 0.1) 0 1px 1px 0 inset;
}

.loading {
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.5em;
    width: 1.5em;
    margin: auto;
    font-size: 1.5em;
}

.spinner {
    color: white;

    &.large {
        font-size: 2em;
    }
}

.placeholder {
    composes: thumbnail;
    width: 150px;
    height: 150px;
    button {
        cursor: default;
    }
}

.srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.toggleZoom {
    border: none;
    background: none;
}

.nsfwInlineImage {
    a {
        display: inline-block;
        border: 2px solid transparent;
    }
}
